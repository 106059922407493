import React, { useState } from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { Contact, Popup } from "../components/popup";
import CustomGraph from "../components/custom-graph";
import { useWindowSize } from "../hooks/useWindowSize";
import CustomGraphResponsive from "../components/custom-graph-responsive";

const CaseStudy = () => {
  const [popupShow, setPopupShow] = useState(false);
  const [componentUpdate, setComponentUpdate] = useState(false);

  const size = useWindowSize();
  const updateComponent = () => {
    setComponentUpdate(!componentUpdate);
  };

  const handlePopupModal = () => {
    setPopupShow(!popupShow);
  };
  return (
    <Layout>
      <div className="casestudypage">
        <div className="container-fluid">
          <div className="wrapperService">
            <div className="innerPageNavigation">
              <ul className="breadcrumbs">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="#">Case study</Link>
                </li>
              </ul>
            </div>
            <h1>
              <span className="circle"></span>Case study
            </h1>
            <section className="row" id="yahoospreets">
              <div className="col-lg-6">
                <div className="casestudySlider">
                  <StaticImage placeholder="none"
                    alt="casestudy"
                    src="../images/casestudy/casestudy-01.webp"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="caseStudyContent">
                  <div className="caseStudyHead">
                    <h2>Yahoo Spreets</h2>
                    <span>(acquired by Yahoo for $40 million)</span>
                  </div>
                  <p>
                    Spreets is an ecommerce website featuring a daily deal on
                    the best things to do, see, eat and buy in cities across
                    Australia.
                  </p>

                  <p>
                    Receive day's best deal from a range of group buying sites &
                    view all the deals near you, or explore another city.
                  </p>

                  <p>
                    Xminds built the web and mobile applications from scratch.
                  </p>

                  <p>
                    After 11 months of development Spreets was acquired by Yahoo
                    for $40 million.
                  </p>
                </div>
              </div>
            </section>
            <section className="row" id="pocketsport">
              <div className="col-lg-6">
                <div className="casestudySlider">
                  <StaticImage placeholder="none"
                    alt="casestudy"
                    src="../images/casestudy/casestudy-02.webp"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="caseStudyContent">
                  <div className="caseStudyHead">
                    <h2>Pocket Sport</h2>
                  </div>
                  <p>
                    Pocket Sport is a London based lifestyle brand with the
                    intention of creating an intimate community through the
                    comfort of leisurewear and the joys of an active lifestyle.
                  </p>
                </div>
              </div>
            </section>
            <section className="row" id="smartqed">
              <div className="col-lg-6">
                <div className="casestudySlider">
                  <StaticImage placeholder="none"
                    alt="casestudy"
                    src="../images/casestudy/casestudy-03.webp"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="caseStudyContent">
                  <div className="caseStudyHead">
                    <h2>Smartqed</h2>
                  </div>
                  <p>
                    SmartQED OpsSpace is a collaborative, visual workspace for
                    systematic Cause Analysis by teams.
                  </p>
                  <p>
                    It automatically learns from user actions using advanced AI
                    and Machine Learning techniques.
                  </p>
                </div>
              </div>
            </section>
            <section className="row" id="keepitcleaner">
              <div className="col-lg-6">
                <div className="casestudySlider">
                  <StaticImage placeholder="none"
                    alt="casestudy"
                    src="../images/casestudy/casestudy-04.webp"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="caseStudyContent">
                  <div className="caseStudyHead">
                    <h2>Keep it cleaner</h2>
                  </div>
                  <p>
                    Keep IT Cleaner is an Australian based multifaceted brand
                  </p>

                  <p>
                    It offers a range of health food products & an online
                    subscription-based lifestyle program.
                  </p>

                  <p>
                    Keep IT Cleaner is an Australian based multifaceted brand.
                    It offers a range of health food products & an online
                    subscription-based lifestyle program. Subscribers have
                    access to a body-positive health program which shares daily
                    workouts, healthy recipes and wellbeing tips.
                  </p>

                  <p>Xminds built the entire application from scratch.</p>
                </div>
              </div>
            </section>
            <section className="row" id="betterclinics">
              <div className="col-lg-6">
                <div className="casestudySlider">
                  <StaticImage placeholder="none"
                    alt="casestudy"
                    src="../images/casestudy/casestudy-05.webp"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="caseStudyContent">
                  <div className="caseStudyHead">
                    <h2>Better Clinics</h2>
                  </div>
                  <p>
                    Better Clinics is an online practice management software for health & fitness professionals.

                  </p>

                  <p>
                    Its features are appointment scheduling, online booking, customer management, invoices, marketing, team management.
                  </p>
                  <p>
                    One can easily access their clinical information securely from anywhere.
                  </p>

                </div>
              </div>
            </section>
            <section className="row" id="plenty">
              <div className="col-lg-6">
                <div className="casestudySlider">
                  <StaticImage placeholder="none"
                    alt="casestudy"
                    src="../images/casestudy/casestudy-06.webp"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="caseStudyContent">
                  <div className="caseStudyHead">
                    <h2>Plenty</h2>
                  </div>
                  <p>
                    Plenty provides users with a personalized, comprehensive
                    financial plan for free.
                  </p>
                  <div className="caseStudyPlentyList">
                    <p>It consists of two set of tools:</p>
                    <strong>Personal Financial Management Tool (PFM)</strong>
                    <ul>
                      <li>
                        PFM tool helps people to stay on top of their everyday
                        financial lives.
                      </li>
                    </ul>
                    <strong>
                      Statement of Advice (SOA) generation engine/tool
                    </strong>
                    <ul>
                      <li>
                        Off the data in the PFM, generates a financial plan for
                        the client.
                      </li>
                      <li>
                        The financial plans are based on the users inputs in the
                        PFM.
                      </li>
                      <li>
                        A set of algorithmic rules will determine what is
                        featured in the financial plan.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </section>
            <section className="row" id="thepodcastnetwork">
              <div className="col-lg-6">
                <div className="casestudySlider">
                  <StaticImage placeholder="none"
                    alt="casestudy"
                    src="../images/casestudy/casestudy-07.webp"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="caseStudyContent">
                  <div className="caseStudyHead">
                    <h2>The Podcast Network</h2>
                  </div>
                  <p>
                    The Podcast Network brings together talented and committed
                    podcasters from around the world to produce high quality
                    programming for a global audience.
                  </p>
                  <p>
                    They launched the world’s first podcast network in February
                    2005.
                  </p>
                  <p>
                    It is a leading publisher of quality podcasts on subjects
                    including technology, business, entertainment, lifestyle,
                    comedy and history.
                  </p>
                </div>
              </div>
            </section>
            <section className="row" id="kingcontent">
              <div className="col-lg-6">
                <div className="casestudySlider">
                  <StaticImage placeholder="none"
                    alt="casestudy"
                    src="../images/casestudy/casestudy-08.webp"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="caseStudyContent">
                  <div className="caseStudyHead">
                    <h2>King content</h2>
                    <span>(acquired by Isentia for $48 million) </span>
                  </div>
                  <p>
                    Australia's multi award winning digital content marketing
                    and creation agency now acquired by Isentia.
                  </p>
                  <p>
                    Xminds has built a digital experience platform from scratch
                    to connect and create a personalized CMS for King Contents
                    customers with a secure platform named Communiqué.
                  </p>
                  <p>
                    Communiqué is a software-as-a-service technology platform
                    that provides King Content and its clients with a
                    centralized system that houses the entire process of its
                    content marketing.
                  </p>
                </div>
              </div>
            </section>
            <section className="row" id="drvoip">
              <div className="col-lg-6">
                <div className="casestudySlider">
                  <StaticImage placeholder="none"
                    alt="casestudy"
                    src="../images/casestudy/casestudy-09.webp"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="caseStudyContent">
                  <div className="caseStudyHead">
                    <h2>DrVoip</h2>
                  </div>
                  <p>
                    DrVoip is a portal that is basically an SMS to Email to SMS
                    application using keyword matching.
                  </p>
                  <p>
                    It handles a variety of communication channels with SMS and
                    call centre capabilities.
                  </p>
                  <p>
                    Users can setup various channels for their support services
                    with SMS push pull services or even as a full fledged call
                    centre module that can handle - voice, video, chat.
                  </p>
                </div>
              </div>
            </section>
            <section className="row" id="datafree">
              <div className="col-lg-6">
                <div className="casestudySlider">
                  <StaticImage placeholder="none"
                    alt="casestudy"
                    src="../images/casestudy/casestudy-10.webp"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="caseStudyContent">
                  <div className="caseStudyHead">
                    <h2>Datafree</h2>
                  </div>
                  <p>
                    Datafree is an Australian based platform that engages its mobile
                    audience by making content delivery data free.
                  </p>
                  <p>
                    Xminds has built a publisher portal, using which the user
                    can register an account and start building website or mobile
                    app development.
                  </p>
                  <p>
                    Apart from this Xminds has created a user management
                    dashboard for managing ads for their publishers and media
                    buyers or advertisers.
                  </p>
                </div>
              </div>
            </section>
            <section className="row" id="pleezpay">
              <div className="col-lg-6">
                <div className="casestudySlider">
                  <StaticImage placeholder="none"
                    alt="casestudy"
                    src="../images/casestudy/casestudy-11.webp"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="caseStudyContent">
                  <div className="caseStudyHead">
                    <h2>PleezPay</h2>
                  </div>
                  <p>
                    PleezPay is an Australian based online event management
                    platform.
                  </p>
                  <p>
                    It provides customised ticket & shopping cart pages, event
                    dashboard, eTickets etc.
                  </p>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      <section className="workSection">
        <div className="container-fluid">
          <div className="wrapperService">
            <h1>
              <span className="circle"></span>How we work
            </h1>
            <div className="row">
              {size?.width > 991 ? (
                <div className="graphDesktop">
                  <CustomGraph
                    handleComponentUpdate={updateComponent}
                    status={componentUpdate}
                  />
                </div>
              ) : (
                <div className="graphMobile">
                  <CustomGraphResponsive
                    handleComponentUpdate={updateComponent}
                    status={componentUpdate}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      <section className="contactSection">
        <div className="container-fluid">
          <div className="wrapperService">
            <div className="contactRelative">
              <div className="contactTitle">
                <div className="casestudySlider">
                  <h1>
                    <span className="circle"></span>Contact us
                  </h1>
                </div>
              </div>
              <Contact type="static" handleChange={handlePopupModal} />
            </div>
          </div>
        </div>
      </section>
      <Popup show={popupShow} handleClose={handlePopupModal} body="success" />
    </Layout>
  );
};
export const Head = () => (
  <Seo
    title="Case study | Xminds infotech"
    description="Trusted by more than 2000 start-ups and other leading businesses. This is how our effective and efficient software solutions help firms succeed."
    url="https://www.xminds.com/case-study"
  />
);

export default CaseStudy;
